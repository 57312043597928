import { useEffect, useRef } from 'react';
import { Menu, Navigation, MobileNavDropdown, MenuProps, SubMenuProps } from '..';
import { useBreakpoint, useNavContext } from '../../hooks';
import { Breakpoint, Defaults } from '../../static/constants';
import { Keys } from '../../types';
import { classes } from '../../utils';
import styles from './GlobalNav.module.scss';

export const GlobalNav = () => {
  const {
    expanded,
    header,
    data,
    activeMenus,
    keyPressed,
    pressKey,
    closeAllMenus,
    setGnHeight,
    gnHeight
  } = useNavContext();
  const { 'gn-main': gnMain, 'is-open': isOpen, 'bg-overlay': bgOverlay, visible } = styles;
  const bp = useBreakpoint();
  const gnClasses = classes(gnMain, { [isOpen]: expanded });
  const gnReference = useRef<HTMLDivElement>(null);

  const handleKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
    e.preventDefault();
    pressKey(e.key);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (activeMenus.length && ![Keys.Shift, Keys.Tab].includes(e.key as Keys)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (keyPressed.key === Keys.Escape) {
      closeAllMenus();
    }
  }, [keyPressed]);

  useEffect(() => {
    setGnHeight(
      (bp === Breakpoint.SM || bp === Breakpoint.MD) && expanded ? window.innerHeight : 0
    );
  }, [bp, expanded, window.innerHeight]);

  const allMenus = data.menus.reduce((menus, menu) => {
    if (menu.mobileOnly) {
      return menus;
    }

    menus.push(menu);
    const content = menu.content as SubMenuProps;

    if (content?.items) {
      menus.push(...(content.items as MenuProps[]));
    }

    return menus;
  }, [] as MenuProps[]);

  const currentMenu = activeMenus.length > 0 ? activeMenus[activeMenus.length - 1] : undefined;

  const showBgOverlay =
    ((bp === Breakpoint.SM || bp === Breakpoint.MD) && expanded) || Boolean(currentMenu?.content);

  return (
    <nav aria-label={data.strings?.altTextLabels?.navigation || Defaults.LABELS.NAVIGATION}>
      <div
        className={gnClasses}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        role="none"
        ref={gnReference}
        style={{ height: gnHeight || undefined }}
      >
        <div className={classes(bgOverlay, { [visible]: showBgOverlay })} />
        <Navigation loginStatus={header.loginStatus} />
        <MobileNavDropdown loginStatus={!!header.loginStatus} />
        <>
          {allMenus.map((menu, index) => (
            <Menu {...menu} key={`menu-${menu.label}`} testid={`${menu.contentType}-${index}`} />
          ))}
        </>
      </div>
    </nav>
  );
};
