import { SvgProps } from '../SvgRendererTypes';

export const QuestionmarkCircleIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Questionmark Circle Icon</title>
      <path
        fill={fillColor}
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 21C7.04 21 3 16.96 3 12C3 7.04 7.04 3 12 3C16.96 3 21 7.04 21 12C21 16.96 16.96 21 12 21Z"
      />
      <path
        fill={fillColor}
        d="M11.77 16.09C11.21 16.09 10.75 16.55 10.75 17.11C10.75 17.67 11.21 18.13 11.77 18.13C12.33 18.13 12.8 17.67 12.8 17.11C12.8 16.55 12.34 16.09 11.77 16.09Z"
      />
      <path
        fill={fillColor}
        d="M12.03 5.88C11 5.88 10.16 6.14 9.54 6.66C8.85 7.22 8.44 8 8.44 8.74C8.44 9.16 8.69 9.43 9.08 9.43C9.47 9.43 9.64 9.19 9.79 8.81C10.19 7.71 10.91 7.2 12.05 7.2C13.19 7.2 14.08 7.92 14.08 8.94C14.08 9.75 13.77 10.25 12.75 11.06C11.36 12.17 11.01 12.79 11.01 14.04C11.01 14.51 11.3 14.82 11.74 14.82C12.05 14.82 12.43 14.68 12.48 14.04C12.55 13.15 12.71 12.81 13.82 11.93C15.11 10.89 15.59 10.08 15.59 8.95C15.59 7.14 14.13 5.88 12.03 5.88Z"
      />
    </svg>
  );
};
