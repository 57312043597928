import { SvgProps } from '../SvgRendererTypes';

export const HomeSecuritySystemsIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Home Security System Icon</title>
      <path
        fill={fillColor}
        d="M19.99 8.49L13.25 2.48C12.89 2.16 12.45 2 12 2C11.55 2 11.11 2.16 10.75 2.48L4.01 8.49C3.37 9.06 3 9.89 3 10.76V18.99C3 20.65 4.3 21.99 5.91 21.99H18.09C19.7 21.99 21 20.65 21 18.99V10.76C21 9.89 20.63 9.06 19.99 8.49ZM20 19C20 20.1 19.14 21 18.09 21H5.91C4.86 21 4 20.1 4 19V10.77C4 10.18 4.25 9.63 4.67 9.25L11.41 3.24C11.57 3.09 11.78 3.01 11.99 3.01C12.2 3.01 12.41 3.09 12.57 3.24L19.31 9.25C19.74 9.63 19.98 10.19 19.98 10.78V19.01L20 19Z"
      />
      <path
        fill={fillColor}
        d="M8.53 11.69C9.17065 11.69 9.69 11.1707 9.69 10.53C9.69 9.88935 9.17065 9.37 8.53 9.37C7.88935 9.37 7.37 9.88935 7.37 10.53C7.37 11.1707 7.88935 11.69 8.53 11.69Z"
      />
      <path
        fill={fillColor}
        d="M12 11.69C12.6406 11.69 13.16 11.1707 13.16 10.53C13.16 9.88935 12.6406 9.37 12 9.37C11.3593 9.37 10.84 9.88935 10.84 10.53C10.84 11.1707 11.3593 11.69 12 11.69Z"
      />
      <path
        fill={fillColor}
        d="M15.47 11.69C16.1107 11.69 16.63 11.1707 16.63 10.53C16.63 9.88935 16.1107 9.37 15.47 9.37C14.8293 9.37 14.31 9.88935 14.31 10.53C14.31 11.1707 14.8293 11.69 15.47 11.69Z"
      />
      <path
        fill={fillColor}
        d="M8.53 15.16C9.17065 15.16 9.69 14.6406 9.69 14C9.69 13.3593 9.17065 12.84 8.53 12.84C7.88935 12.84 7.37 13.3593 7.37 14C7.37 14.6406 7.88935 15.16 8.53 15.16Z"
      />
      <path
        fill={fillColor}
        d="M12 15.16C12.6406 15.16 13.16 14.6406 13.16 14C13.16 13.3593 12.6406 12.84 12 12.84C11.3593 12.84 10.84 13.3593 10.84 14C10.84 14.6406 11.3593 15.16 12 15.16Z"
      />
      <path
        fill={fillColor}
        d="M15.47 15.16C16.1107 15.16 16.63 14.6406 16.63 14C16.63 13.3593 16.1107 12.84 15.47 12.84C14.8293 12.84 14.31 13.3593 14.31 14C14.31 14.6406 14.8293 15.16 15.47 15.16Z"
      />
      <path
        fill={fillColor}
        d="M8.53 18.63C9.17065 18.63 9.69 18.1106 9.69 17.47C9.69 16.8293 9.17065 16.31 8.53 16.31C7.88935 16.31 7.37 16.8293 7.37 17.47C7.37 18.1106 7.88935 18.63 8.53 18.63Z"
      />
      <path
        fill={fillColor}
        d="M12 18.63C12.6406 18.63 13.16 18.1106 13.16 17.47C13.16 16.8293 12.6406 16.31 12 16.31C11.3593 16.31 10.84 16.8293 10.84 17.47C10.84 18.1106 11.3593 18.63 12 18.63Z"
      />
      <path
        fill={fillColor}
        d="M15.47 18.63C16.1107 18.63 16.63 18.1106 16.63 17.47C16.63 16.8293 16.1107 16.31 15.47 16.31C14.8293 16.31 14.31 16.8293 14.31 17.47C14.31 18.1106 14.8293 18.63 15.47 18.63Z"
      />
    </svg>
  );
};
