import styles from '../components/GlobalNav/GlobalNav.module.scss';
import React, { ReactNode, useState } from 'react';
import { Keys, NavStrings } from '../types';
import { MenuProps } from '../components/Menu/Menu';
import { Mode } from '../static/constants';

export type HeaderProps = Readonly<{
  api?: number;
  geo: string;
  loginStatus?: boolean;
  shop: boolean; // true on RingWebsite false on TV, Blog and Support properties
  locale: string;
  returnTo?: string;
  mode?: Mode;
  transactional?: boolean;
}>;

export type ContentProps = Readonly<{
  strings?: Partial<NavStrings>;
  transactional: boolean;
  menus: Array<MenuProps>;
  sessions?: Record<string, string>;
}>;

export type NavProps = Readonly<{
  header: HeaderProps;
  data: ContentProps;
}>;

export interface NavContextProps {
  toggleExpanded: () => void;
  expanded: boolean;
  header: HeaderProps;
  data: ContentProps;
  activeMenus: Array<MenuProps>;
  openMenu: (menu: MenuProps) => void;
  closeMenu: () => void;
  closeAllMenus: () => void;
  keyPressed: { key: string; timestamp: Date };
  pressKey: (key: string) => void;
  gnHeight: number;
  setGnHeight: (height: number) => void;
}

interface NavStateProviderProps {
  nav: NavProps;
  children: ReactNode;
}

export const NavContext = React.createContext({} as NavContextProps);

export const NavProvider = ({ nav, children }: NavStateProviderProps) => {
  const [expanded, setExpanded] = useState(false);
  const [activeMenus, setActiveMenus] = useState<Array<MenuProps>>([]);
  const [keyPressed, setKeyPressed] = useState({ key: '', timestamp: new Date() });
  const [gnHeight, setGnHeight] = useState(0);
  const { 'gn-open': expandedClass } = styles;

  const { header, data } = nav;

  const toggleExpanded = () => {
    document.querySelector('body')?.classList.toggle(expandedClass);
    setTimeout(() => {
      setExpanded(!expanded);
    }, 100);
  };

  const openMenu = (menu: MenuProps) => {
    setActiveMenus([...activeMenus, menu]);
  };

  const closeMenu = () => {
    setActiveMenus(activeMenus.slice(0, -1));
  };

  const closeAllMenus = () => {
    setActiveMenus([]);
  };

  const pressKey = (key: string) => {
    if (key !== Keys.Tab && key !== Keys.Shift) {
      setKeyPressed({ key, timestamp: new Date() });
    }
  };

  return (
    <NavContext.Provider
      value={{
        expanded,
        toggleExpanded,
        header,
        data,
        activeMenus,
        openMenu,
        closeMenu,
        closeAllMenus,
        keyPressed,
        pressKey,
        gnHeight,
        setGnHeight
      }}
    >
      {children}
    </NavContext.Provider>
  );
};
