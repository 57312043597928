import { SvgProps } from '../SvgRendererTypes';

export const BuildSystemIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Build System Icon</title>
      <path
        fill={fillColor}
        d="M19.99 8.49L13.25 2.48C12.89 2.16 12.45 2 12 2C11.55 2 11.11 2.16 10.75 2.48L4.01 8.49C3.37 9.06 3 9.89 3 10.76V18.99C3 20.65 4.3 21.99 5.91 21.99H18.09C19.7 21.99 21 20.65 21 18.99V10.76C21 9.89 20.63 9.06 19.99 8.49ZM20 19C20 20.1 19.14 21 18.09 21H5.91C4.86 21 4 20.1 4 19V10.77C4 10.18 4.25 9.63 4.67 9.25L11.41 3.24C11.57 3.09 11.78 3.01 11.99 3.01C12.2 3.01 12.41 3.09 12.57 3.24L19.31 9.25C19.74 9.63 19.98 10.19 19.98 10.78V19.01L20 19Z"
      />
      <path
        fill={fillColor}
        d="M16.11 12.93H12.5V9.32C12.5 9.04 12.28 8.82 12 8.82C11.72 8.82 11.5 9.04 11.5 9.32V12.93H7.89C7.61 12.93 7.39 13.15 7.39 13.43C7.39 13.71 7.61 13.93 7.89 13.93H11.5V17.54C11.5 17.82 11.72 18.04 12 18.04C12.28 18.04 12.5 17.82 12.5 17.54V13.93H16.11C16.39 13.93 16.61 13.71 16.61 13.43C16.61 13.15 16.39 12.93 16.11 12.93Z"
      />
    </svg>
  );
};
