import { SvgProps } from '../SvgRendererTypes';

export const RingLogo = (props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
    >
      <title>Ring Logo Icon</title>
      <path
        fill="#000f30"
        d="M430.77,107.42c-63.99,0-115.99,51.03-115.99,116.95v172.16c0,2.25,1.76,4.01,4.01,4.01h55.99c2.25,0,4.01-1.76,4.01-4.01v-172.97c0-28.8,23.35-52.01,52.01-52.01s52.16,23.35,52.16,52.01v172.97c0,2.25,1.76,4.01,4.01,4.01h56c2.25,0,4.01-1.76,4.01-4.01v-172.16c0-65.92-52.16-116.95-116.17-116.95h-.03Z"
      />
      <path
        fill="#000f30"
        d="M275.37,396.7c0,2.25-1.76,4.01-4.01,4.01h-55.99c-2.25,0-4.01-1.76-4.01-4.01v-252.95c.32-17.44,14.55-31.52,32.01-31.52s31.69,14.08,32.01,31.52v252.95Z"
      />
      <path
        fill="#000f30"
        d="M153.43,181.51c-1.27,1.76-3.84,2.08-5.6.81-8.95-6.7-19.34-10.71-31.03-10.71-28.95-.49-52.79,23.67-52.79,52.79v172.16c0,2.25-1.76,4.01-4.01,4.01H4.01c-2.25,0-4.01-1.76-4.01-4.01v-172.16c0-65.14,52.01-117.29,116.15-117.29,26.26,0,51.2,10.24,69.93,23.03,1.91,1.27,2.23,3.84.96,5.6l-33.6,45.77Z"
      />
      <circle fill="#007acc" cx="243.4" cy="40.03" r="40.03" />
      <path
        fill="#000f30"
        fillRule="evenodd"
        d="M813.65,226.11c-1.57-72.76-55.53-118.25-116-118.57-64.97-.49-118.08,53.6-118.08,118.57v61.91c0,64.8,52.48,117.27,117.27,117.27,20.32-.32,36.31-7.53,48.95-19.05,1.93-1.76,4.96-.49,4.96,2.08v31.37c0,15.36-6.24,29.93-16.65,40.81-6.09,7.04-16.65,12.15-23.67,14.08-38.88,11.2-59.52-7.19-85.12-28.17-1.93-1.59-4.65-1.12-6.09.81l-33.24,44.84c-1.12,1.44-.95,3.52.32,4.79,14.23,15.21,38.39,34.55,73.11,42.23,18.56,3.52,37.12,3.52,50.71,1.44,11.05-1.59,21.76-4.96,31.84-9.44,9.93-4.47,19.2-10.39,27.68-17.29,8.31-6.87,15.84-15.04,22.08-23.84,6.24-8.95,11.37-18.73,15.04-29.12,3.84-10.56,6.09-21.76,6.87-32.96.17-2.72.32-5.45.32-8.17l-.32-193.61ZM749.45,295.47c-3.7,26.02-26.17,46.03-53.05,46.03-25.07,0-46.31-17.41-52.05-40.86-.06-.24-.11-.48-.17-.74-.32-1.41-.6-2.84-.81-4.29-1.47-9.17-2.42-23.36-2.42-39.31s.95-30.14,2.42-39.31c.21-1.45.49-2.88.81-4.29.06-.25.11-.5.17-.74,5.75-23.45,26.98-40.86,52.05-40.86s44.65,16.09,51.31,38.16c.42,1.22.81,2.79,1.19,4.64,0,0,0,0,0,.01,1.78,8.88,2.96,24.51,2.96,42.32,0,15.91-.94,30.08-2.41,39.25Z"
      />
    </svg>
  );
};
