import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { GlobalNav } from './components';
import { HeaderProps, NavProvider } from './context/NavContext';
import { Defaults, Mode, NO_CONTENT_CONFIG } from './static/constants';
import contentStorage from './helpers/contentStorage';
import setStaticNav from './helpers/setStaticNav';

import './index.scss';

/**
 * Render the Global Nav via React
 * @param initialState
 * @param jsonData
 */
export async function render() {
  const root = document.getElementById('gn-root');
  const staticHeader = root?.querySelector('.header-wrapper') as HTMLDivElement;

  const initialState: HeaderProps = root?.dataset?.state
    ? JSON.parse(root.dataset.state)
    : {
        geo: Defaults.MARKETPLACE,
        locale: Defaults.LOCALE
      };

  try {
    const jsonData =
      initialState.mode === Mode.NoContent
        ? NO_CONTENT_CONFIG
        : await contentStorage.fetchContentData(contentStorage.validateLocale(initialState.locale));

    if (!jsonData) return;

    ReactDOM.render(
      <StrictMode>
        <NavProvider
          nav={{
            header: initialState,
            data: {
              ...jsonData,
              transactional: initialState.transactional ?? !!jsonData.transactional
            }
          }}
        >
          <GlobalNav />
        </NavProvider>
      </StrictMode>,
      root,
      () => setTimeout(() => document.dispatchEvent(new Event('gn_loaded')), 500)
    );
  } catch (err) {
    setStaticNav(staticHeader);
    console.error(`Failed to replace static Nav for GN. ${err}`);
  }
}

render();
