import { SvgProps } from '../SvgRendererTypes';

export const GuardServiceIcon = ({ fillColor, ...props }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Guard Service Icon</title>
      <path
        fill={fillColor}
        d="M5.90998 20C4.85998 20 3.99998 19.1 3.99998 18V10.77C3.99998 10.18 4.24998 9.63001 4.66998 9.25001L11.41 3.24001C11.57 3.09001 11.78 3.01001 11.99 3.01001C12.2 3.01001 12.41 3.09001 12.57 3.24001L19.31 9.25001C19.74 9.63001 19.98 10.19 19.98 10.78V11.51C20.06 11.53 20.13 11.55 20.21 11.57C20.5 11.66 20.75 11.75 20.98 11.83V10.77C20.98 9.90001 20.61 9.07001 19.97 8.50001L13.23 2.49001C12.87 2.17001 12.43 2.01001 11.98 2.01001C11.53 2.01001 11.09 2.17001 10.73 2.49001L3.98998 8.50001C3.34998 9.07001 2.97998 9.90001 2.97998 10.77V18C2.97998 19.66 4.27998 21 5.88998 21H13.58C13.21 20.66 12.93 20.32 12.7 20H5.88998H5.90998Z"
      />
      <path
        fill={fillColor}
        d="M19.9 12.51C18.5 12.09 17.46 12 17.46 12C17.46 12 16.42 12.09 15.02 12.51C13.62 12.93 12.96 13.4 12.96 13.4V18.45C12.96 18.45 13.36 19.62 14.62 20.59C15.87 21.56 17.46 22 17.46 22C17.46 22 19.05 21.57 20.3 20.59C21.56 19.62 21.96 18.45 21.96 18.45V13.4C21.96 13.4 21.3 12.93 19.9 12.51ZM20.96 18.25C20.83 18.53 20.45 19.21 19.69 19.81C18.9 20.43 17.92 20.8 17.46 20.96C17 20.8 16.02 20.42 15.23 19.81C14.47 19.22 14.09 18.53 13.96 18.26V14C14.26 13.86 14.7 13.67 15.31 13.48C16.36 13.16 17.19 13.05 17.46 13.01C17.73 13.04 18.56 13.16 19.61 13.48C20.22 13.66 20.67 13.85 20.96 14V18.26V18.25Z"
      />
    </svg>
  );
};
